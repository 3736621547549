import React from "react";
import { Container,Row, Col } from "reactstrap"

import "../../styles/contactus.css"

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';



function ContactUs(){
  return(
    <Container className="bg-grey d-flex flex-column justify-content-center" fluid>
      <Row className="justify-content-center mt-5">
        <h2 className="text-center font-weight-bold">¿Necesitas ayuda? Contáctanos.</h2>
      </Row>

      <Row className="text-center my-5">
        <Col sm={12} md={12} className="">
          <div>
            <a href="mailto:contacto@correo.com?Subject=Me gustaria%20pedir%20informacíon" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon size="3x" icon={["fas", "envelope"]} color="#FF3366" className="mr-5"/>
            </a>

            <a href="https://api.whatsapp.com/send?phone=2222222222&text=Hola,%20quisiera%20más%20informacíon%20" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon size="3x" icon={["fab", "whatsapp"]} color="#FF3366" className="ml-5"/>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactUs
