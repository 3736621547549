import React, { Component } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronCircleRight,
  faDownload,
  faFile,
} from "@fortawesome/free-solid-svg-icons"

import "../../styles/guides.css"

import manual from "../../pdfs/guides/manualMarca.pdf"

const type = [
  {
    title: "01",
    text: "Manual de marca",
    src: manual,
  },
]

export default class Guides extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: "01",
      text: "Manual de marca",
      src: manual,
    }
  }

  setType = (title, text, src) => {
    this.setState({
      selType: title,
      text,
      src,
    })
  }

  render() {
    const { selType, src: srcG, text: textG } = this.state
    const firstColor = "6BCBB8"
    const secondColor = "0E797F"

    return (
      <Container
        style={{ position: "relative" }}
        className="bg-guides "
        id="guides"
        fluid
      >
        <div
          className="innerbg"
          style={{
            background: `linear-gradient(45deg,#${firstColor},#${secondColor})`,
          }}
        />
        <Container>
          <Row className="">
            <Col sm={12} md={6} className="bg-guides">
              <p className="text-guides text-white ml-5 mt-5 "> GUÍAS </p>
              <Nav className="mt-5 font-weight-bolder text-white">
                {type.map(({ icon, title, text, src }, i) => (
                  <NavItem
                    onClick={() => this.setType(title, text, src)}
                    onMouseEnter={() => this.setType(title, text, src)}
                    className=""
                    key={i}
                  >
                    <NavLink
                      tag="span"
                      active={selType === text}
                      className="mt-3"
                    >
                      {" "}
                      <h3 className="text_hovers">
                        <FontAwesomeIcon
                          size="1x"
                          icon={faChevronCircleRight}
                        />
                        &nbsp;
                        {title} &nbsp;{text}
                      </h3>
                      <Row className="ml-4">
                        <a
                          href={srcG}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="guides_dwnld"
                        >
                          <FontAwesomeIcon size="2x" icon={faFile} />
                        </a>
                        <a href={srcG} download className="guides_dwnld">
                          <FontAwesomeIcon size="2x" icon={faDownload} />
                        </a>
                      </Row>
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </Col>
            <Col sm={12} md={6} className="dispi">
              <TransitionGroup key={srcG} appear={true} exit={true}>
                <CSSTransition timeout={300} className="mt-5 ml-5">
                  <div>
                    <p className="ml-5 mt-5 text-guides text-green">
                      {selType}
                    </p>
                    <h2 className="ml-5 mt-5 font-weight-bold">{textG}</h2>
                    <a
                      href={srcG}
                      download
                      className="download_resources_iconcol d-flex flex-row-reverse mb-5"
                    >
                      <FontAwesomeIcon size="2x" icon={faDownload} />
                    </a>
                    <embed
                      src={srcG}
                      type="application/pdf"
                      width="100%"
                      height="600"
                    />
                    <br />
                  </div>
                </CSSTransition>
              </TransitionGroup>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}
